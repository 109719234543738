<template>
  <!-- если пройдена проверка готовности создания экспертизы, показываем данные по экспертизе -->
  <router-view v-if="expertiseReady" :init-data="initData" />
  <v-container v-else>
    <!-- затенение и крутилка на проверку -->
    <v-progress-linear v-if="loading" indeterminate color="secondary" />
    <!-- алерты если экспертиза не готова -->
    <v-alert v-if="error" type="error" outlined dense>
      Ошибка получения экспертизы: {{ error }}
    </v-alert>
    <v-alert v-else-if="!loading" type="warning" dense text>
      Не возможно создать "Представление о ЛП", так как не готовы экспертизы:<br />
      <ul class="font-weight-bold mt-3">
        <li v-if="!aboutReady">Информация о препарате;</li>
        <li v-if="!keiReady">Экспертиза КЭИ;</li>
        <li v-if="!avbReady">Экспертиза АВБ;</li>
        <li v-if="!analogReady">Аналоги;</li>
        <li v-if="!otherReady">Прочие данные;</li>
      </ul>
    </v-alert>
  </v-container>
</template>

<script>
import {
  SUB_EX_ABOUT,
  SUB_EX_ANALOG,
  SUB_EX_AVB,
  SUB_EX_KEI,
  SUB_EX_OTHER,
} from '@/store/const/expertise'
import { mapActions } from 'vuex'

export default {
  name: 'medicine-summary',
  data: () => ({
    loading: true,
    error: '',
    // данные из экспертизы
    initData: null,
  }),
  async created() {
    this.loading = true
    try {
      const { expertise } = await this.GET_PLP_REPORT([
        SUB_EX_ABOUT,
        SUB_EX_AVB,
        SUB_EX_KEI,
        SUB_EX_ANALOG,
        SUB_EX_OTHER,
      ])
      this.initData = expertise
    } catch (err) {
      this.error = `${err.message} ${err.error}`.trimEnd()
      throw err
    } finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions(['GET_PLP_REPORT']),
  },
  computed: {
    expertiseReady() {
      return (
        !this.loading &&
        this.aboutReady &&
        this.keiReady &&
        this.avbReady &&
        this.analogReady &&
        this.otherReady
      )
    },
    aboutReady() {
      return !!this.initData?.about
    },
    keiReady() {
      return !!this.initData?.kei?.ka
    },
    avbReady() {
      return !!this.initData?.avb?.ka
    },
    analogReady() {
      return !!this.initData?.analog?.AL
    },
    otherReady() {
      return !!this.initData?.other
    },
  },
}
</script>
