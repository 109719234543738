<template>
  <!-- если пройдена проверка готовности создания экспертизы, показываем данные по экспертизе -->
  <router-view v-if="initData" :init-data="initData" />
  <v-container v-else>
    <!-- затенение и крутилка на проверку -->
    <v-progress-linear v-if="loading" indeterminate color="secondary" />
    <!-- алерты если экспертиза не готова -->
    <v-alert v-if="error" type="error" outlined dense>
      Ошибка получения экспертизы: {{ error }}
    </v-alert>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'medicine-reports',
  data: () => ({
    loading: true,
    error: '',
    // данные из экспертизы
    initData: null,
  }),
  async created() {
    this.loading = true
    try {
      const { expertise } = await this.GET_PLP_REPORT()
      this.initData = expertise
    } catch (err) {
      this.error = `${err.message} ${err.error}`.trimEnd()
      throw err
    } finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions(['GET_PLP_REPORT']),
  },
}
</script>
