<template>
  <VersionSelect v-if="plp_task_id" fluid last>
    <template #content="{ data, readonly, onChange, onBlur, onCreate }">
      <SummaryPurchase
        :value="data"
        :readonly="readonly"
        :onCreate="onCreate"
        :init-data="initData"
        @change="onChange"
        @blur="onBlur"
      />
    </template>
  </VersionSelect>
</template>

<script>
import { mapGetters } from 'vuex'
import VersionSelect from '@/components/VersionSelect'
import SummaryPurchase from '@/components/expSummary/summaryPurchase/SummaryPurchase'

export default {
  name: 'MedicineSummaryPurchase',
  components: { SummaryPurchase, VersionSelect },
  props: ['initData'],
  computed: {
    ...mapGetters(['plp_task_id']),
  },
}
</script>
