var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.plp_task_id)?_c('VersionSelect',{attrs:{"fluid":"","last":""},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var data = ref.data;
var readonly = ref.readonly;
var onChange = ref.onChange;
var onBlur = ref.onBlur;
var onCreate = ref.onCreate;
return [_c('SummaryAdditional',{attrs:{"value":data,"readonly":readonly,"onCreate":onCreate,"init-data":_vm.initData},on:{"change":onChange,"blur":onBlur}})]}},{key:"prepend-bottom",fn:function(ref){
var is_completed = ref.is_completed;
var data = ref.data;
return [(data && is_completed)?_c('btn-with-confirm',{attrs:{"loading":_vm.loading,"left":"","iconMain":"mdi-check-bold","color":"primary","title":"Представление о ЛП не готово к выгрузке - требуется доработка"},on:{"click":function($event){return _vm.setComplete(false)}}},[_vm._v("Представление о ЛП - не готово!")]):(data)?_c('btn-with-confirm',{attrs:{"loading":_vm.loading,"left":"","iconMain":"mdi-arrow-up-left-bold","color":"success","title":"Представление о ЛП готово к выгрузке"},on:{"click":function($event){return _vm.setComplete(true)}}},[_vm._v("Представление о ЛП - готово")]):_vm._e()]}},{key:"bottom",fn:function(){return [_c('span')]},proxy:true}],null,false,99481490)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }