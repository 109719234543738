<template>
  <VersionSelect v-if="plp_task_id" fluid last>
    <template #content="{ data, readonly, onChange, onBlur, onCreate }">
      <SummaryAdditional
        :value="data"
        :readonly="readonly"
        :onCreate="onCreate"
        :init-data="initData"
        @change="onChange"
        @blur="onBlur"
      />
    </template>
    <template #prepend-bottom="{ is_completed, data }">
      <btn-with-confirm
        v-if="data && is_completed"
        :loading="loading"
        left
        iconMain="mdi-check-bold"
        color="primary"
        @click="setComplete(false)"
        title="Представление о ЛП не готово к выгрузке - требуется доработка"
        >Представление о ЛП - не готово!</btn-with-confirm
      >
      <btn-with-confirm
        v-else-if="data"
        :loading="loading"
        left
        iconMain="mdi-arrow-up-left-bold"
        color="success"
        @click="setComplete(true)"
        title="Представление о ЛП готово к выгрузке"
        >Представление о ЛП - готово</btn-with-confirm
      >
    </template>
    <template #bottom><span /></template>
  </VersionSelect>
</template>

<script>
import VersionSelect from '@/components/VersionSelect'
import { mapActions, mapGetters } from 'vuex'
import SummaryAdditional from '@/components/expSummary/summaryAdditional/SummaryAdditional'
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
export default {
  name: 'MedicineSummaryAdditional',
  components: { BtnWithConfirm, SummaryAdditional, VersionSelect },
  props: ['initData'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['plp_task_id']),
  },
  methods: {
    ...mapActions(['UPDATE_PLP_TASK', 'UPDATE_PLP_TASK_REWORK']),
    async setComplete(is_completed = false) {
      this.loading = true
      try {
        if (is_completed) {
          await this.UPDATE_PLP_TASK({
            is_completed,
          })
        } else {
          await this.UPDATE_PLP_TASK_REWORK()
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
